import React from 'react';
import Dropzone from 'react-dropzone';
import UploadImageIcon from "../../images/Upload_Image_Icon.svg"

class DragAndDropPDFSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.getFile = this.getFile.bind(this);
    }

    enableAlertDialog(title, message) {
        this.setState({alertDialogTitle: title, alertDialogMessage: message, showAlertDialog: true});
    }

    disableAlertDialog() {
        this.setState({showAlertDialog: false});
    }

    componentDidMount() {
     
    }

    getFile(inputFile) {
        if(inputFile[0].type === "application/pdf")
        {
            this.props.onGetPDF(inputFile[0])
        }
        else
        {
            this.props.onNotGetPDF();
        }
    }

    render() {
        return (
            <div
                className="dragAndDrop-container"
                style={{
                borderRadius: "0.5em",
                backgroundColor: "#EE413933",
                border: " 2px dashed rgba(238, 65, 57, 0.75)",
                justifyContent: "center",
                alignContent: "center",
                position: "relative"
            }}>
                <Dropzone
                    style={{
                    width: "100%",
                    height: "100%"
                }}
                    onDrop={acceptedFiles => this.getFile(acceptedFiles)}
                    className="noselect">
                    {({getRootProps, getInputProps}) => (
                        <section
                            style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            padding: 16
                        }}>
                            <div
                                {...getRootProps()}
                                style={{
                                display: "flex",
                                flexDirection: "column",
                                justifySelf: "center",
                                alignSelf: "center",
                                height: "100%"
                            }}>
                                <input {...getInputProps()}/>
                                <img style={{height: 36}} alt="upload" src={UploadImageIcon}/>
                                    <div
                                        className="no-select"
                                        style={{
                                        fontSize: "14px",
                                        color: "rgba(0, 0, 0, 0.75)",
                                        fontWeight: 500,
                                        textAlign: "center"
                                    }}>
                                        {"请点击或拖拽PDF到此处"}</div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div >
        );
    }
}


export default DragAndDropPDFSelector;