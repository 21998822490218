import React from 'react';
import PropTypes from 'prop-types';
import "./MessageDialog.scss";
import {debugPrint} from '../../utilities/Utilities';

class MessageDialog extends React.Component
{

    render()
    {
        return <div className='message-dialog-parent'>
            <div className='message-dialog-frame'>
                <div className='message-dialog-title'>{this.props.title}</div>
                <div className='message-dialog-message'>{this.props.message}</div>
                <div className='message-dialog-button-parent'>
                    <div className='message-dialog-button-ok' onClick={this.props.callback}>好的</div>
                    {this.props.needCancel
                        ? <div
                                className='message-dialog-button-cancel'
                                onClick={this.props.cancelCallback}>取消</div>
                        : null}

                </div>
            </div>
        </div>;
    }
}

MessageDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    callback: PropTypes.func,
    cancelCallback: PropTypes.func,
    needCancel: PropTypes.bool
};

MessageDialog.defaultProps = {
    title: "",
    message: "",
    callback: () => {
        debugPrint("One time callback")
    },
    cancelCallback: () => {
        debugPrint("One time callback")
    },
    needCancel: true
}

export default MessageDialog;