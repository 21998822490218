import React from "react";
import PropTypes from "prop-types";
import "./EPaperTopReadCard.scss";
import Number1Icon from "../../images/no1_icon.svg";

class EPaperTopReadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <div className="e-paper-card-parent">
        <a href={this.props.path}>
          <img
            className="e-paper-thumbnail"
            src={this.props.thumbnail}
            alt="e-paper"
          />
        </a>
        <div
          className="p-a-s e-paper-right"
          style={{
            flex: 1,
          }}>
          <div className="e-paper-top-row">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <div>
                <div className="e-paper-title">{this.props.title} </div>
                <div className="e-paper-date">{this.props.date}</div>
              </div>

              <div style={{ width: "36px", height: "36px" }}>
                <img src={this.props.rankingIcon} alt=""/>
              </div>
            </div>
          </div>
          <div className="e-paper-bottom-row">
            <div className="e-paper-viewCount">{this.props.viewCount}</div>
          </div>
        </div>
      </div>
    );
  }
}

EPaperTopReadCard.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  thumbnail: PropTypes.string,
  viewCount: PropTypes.string,
  rankingIcon: PropTypes.string,
};

EPaperTopReadCard.defaultProps = {
  thumbnail: "",
  title: "Title",
  date: "Date",
  viewCount: "42000",
  rankingIcon: Number1Icon,
};

export default EPaperTopReadCard;
