import React from "react";
import "./Pages.scss";
import queryString from "query-string";
import Logo from "../../images/atv_logo.png";
import {login} from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import LoadingScreen from "../widgets/LoadingScreen";
import MessageDialog from "../dialogs/MessageDialog";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            password: "",
            loading: false,
            showMessageDialog: false,
            messageDialogTitle: "",
            messageDialogMessage: ""
        };

        this.performLogin = this
            .performLogin
            .bind(this);
        this.enterDashboard = this
            .enterDashboard
            .bind(this);
        this.enableMessageDialog = this
            .enableMessageDialog
            .bind(this);
    }

    async performLogin(id, pw) {
        this.setState({loading: true});
        let loginResult = await login(id, pw);
        if (loginResult.status === APIResult.SUCCESS) {
            localStorage.setItem("session", JSON.stringify({userID: loginResult.user_id, jwt: loginResult.jwt}));
            this.enterDashboard();
        } else if (loginResult.status === APIResult.FAILED) {
            localStorage.clear();
            this.enableMessageDialog("登入", "不明错误导致登入失败，请联系技术人员。");
            this.setState({loading: false});
        } else if (loginResult.status === APIResult.TIME_OUT) {
            this.enableMessageDialog("登入", "暂时无法连接到伺服器，请稍候再试。");
            this.setState({loading: false});
        }
    }

    enterDashboard() {
        window.location.href = `/dashboard`;
    }

    enableMessageDialog(title, message, callback = null) {
        this.oneTimeCallback = () => {
            if(callback !== null)
            {
              callback();
            }
            this.oneTimeCallback = () => {};
            this.setState({showMessageDialog: false});
        };
        this.setState({messageDialogTitle: title, messageDialogMessage: message, showMessageDialog: true});
    }

    tryLogin()
    {
        const sessionStorage = localStorage.getItem('session');
        if (sessionStorage === null) {
          return;
        }
        let sessionInfo = JSON.parse(sessionStorage);
        let keys = Object.keys(sessionInfo);
        if (!keys.includes("userID") || !keys.includes("jwt")) {
            return;
        }
        if (sessionInfo["userID"].toString().replace(" ", "") === "" && sessionInfo["jwt"].toString().replace(" ", "") === "") {
            return;
        }

        this.performLogin(sessionInfo["userID"], sessionInfo["jwt"]);
    }

    componentDidMount() {
        let urlParams = queryString.parse(this.props.location.search);
        if (urlParams.to === 5 || urlParams.to === "5") {
            this.enableMessageDialog("", "用户登入逾时，请再次登入。");
        } else {
            this.tryLogin();
        }
    }

    render() {
        return (
            <div className="parent center-v full-width">
                <div className="parent-center">
                    <img className="login-icon" src={Logo} alt="logo"/>
                    <input
                        className="max-width login-input m-t-l"
                        type="text"
                        placeholder="账号"
                        value={this.state.id}
                        onChange={(e) => {
                        this.setState({id: e.target.value});
                    }}/>
                    <input
                        className="max-width login-input m-t-m"
                        type="password"
                        placeholder="密码"
                        value={this.state.password}
                        onChange={(e) => {
                        this.setState({password: e.target.value});
                    }}/>
                    <div className="max-width m-t-l button" onClick={()=>{
                      this.performLogin(this.state.id, this.state.password);
                    }}>
                        登入
                    </div>
                </div>
                {this.state.loading
                    ? (<LoadingScreen message="登入中...请稍候"/>)
                    : null}

                {this.state.showMessageDialog
                    ? (<MessageDialog
                        needCancel={false}
                        title={this.state.messageDialogTitle}
                        message={this.state.messageDialogMessage}
                        callback={() => {
                        this.setState({showMessageDialog: false});
                        this.oneTimeCallback();
                    }}/>)
                    : null}
            </div>
        );
    }
}

export default LoginPage;
