import React from "react";
import queryString from "query-string";
import DragAndDropPDFSelector from "../widgets/DragAndDropPDFSelector";
import "./Pages.scss";
import GenericButton from "../widgets/GenericButton";
import {addNewEPaper, updateEPaper, getEPaperDetails, ePaperDuplicatePage} from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import PDFEditor from "../widgets/PDFEditor";
import InputTag from "../widgets/InputTag";
import {getDate, getTime, getTodayDateString} from "../../utilities/Utilities";
import LoadingScreen from "../widgets/LoadingScreen";
import MessageDialog from "../dialogs/MessageDialog";
import BackIcon from "../../images/back_icon.svg";
import DeleteIcon from "../../images/trash_icon.svg";
import DuplicateIcon from "../../images/duplicate_icon.svg";
import PreviewIcon from "../../images/preview_icon.svg";
import HashLoader from "react-spinners/HashLoader";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

class AddEPaperPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bracketsInfo: [],
            hashtags: "",
            pdfFile: null,
            images: [],
            title: "",
            tags: [],
            tagText: "",
            editMode: 0,
            date: getTodayDateString(),
            time: "20:00",
            status: 0,
            showLoadingScreen: false,
            uploadPercentage: 0,
            uploadTimestamp: 0,
            loadingScreenMessage: "",
            showMessageDialog: false,
            messageDialogTitle: "",
            messageDialogMessage: "",
            autoSaving: false,
            showMessageDialogCancelButton: false,
            pages: [],
            epaper_path: "",
            edited: false
        };

        this.ePaperID = 0;

        this.updateTitle = this
            .updateTitle
            .bind(this);
        this.updateDate = this
            .updateDate
            .bind(this);
        this.updateTime = this
            .updateTime
            .bind(this);
        this.addNewEPaper = this
            .addNewEPaper
            .bind(this);
        this.addTag = this
            .addTag
            .bind(this);
        this.onGetPDF = this
            .onGetPDF
            .bind(this);
        this.removeTag = this
            .removeTag
            .bind(this);
        this.updateEPaper = this
            .updateEPaper
            .bind(this);
        this.submitEPaper = this
            .submitEPaper
            .bind(this);
        this.addNewPaperCallback = this
            .addNewPaperCallback
            .bind(this);
        this.updateLoadingScreen = this
            .updateLoadingScreen
            .bind(this);
        this.enterDashboard = this
            .enterDashboard
            .bind(this);
        this.retrieveEPaperDetails = this
            .retrieveEPaperDetails
            .bind(this);
        this.enableMessageDialog = this
            .enableMessageDialog
            .bind(this);
        this.pdfFullyLoadedFunc = this
            .pdfFullyLoadedFunc
            .bind(this);
        this.deleteEPaper = this
            .deleteEPaper
            .bind(this);
        this.oneTimeCallback = () => {};
        this.autoSave = this
            .autoSave
            .bind(this);
        this.updatePages = this
            .updatePages
            .bind(this);
        this.remindUpdated = this
            .remindUpdated
            .bind(this);
        this.onDragTagsEnd = this
            .onDragTagsEnd
            .bind(this);
        this.edited = false;
    }

    componentDidMount() {
        let urlParams = queryString.parse(this.props.location.search);
        if ("id" in urlParams) {
            this.ePaperID = urlParams.id;
            this.setState({ePaperID: this.ePaperID});
            this.retrieveEPaperDetails();
        }

        if (localStorage.getItem("autosave") === "true") {
            this.listenToAutosave = setInterval(this.autoSave, parseInt(localStorage.getItem("autosaveFrequency")) * 60000);

        }
    }

    remindUpdated()
    {
        if (!this.edited) {
            this.edited = true;
            this.addExitConfirmation();
            this.setState({edited: true});
        }
    }

    beforeUnloadHandler(e) {
        e.preventDefault();
        e.returnValue = '此电子报';
    }

    addExitConfirmation() {
        window.addEventListener("beforeunload", this.beforeUnloadHandler, true);
    }
    removeExitConfirmation() {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler, true);
    }

    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    onDragTagsEnd(result) {
        if (!result.destination) {
            return;
        }
        const tags = this.reorder(this.state.tags, result.source.index, result.destination.index);

        this.setState({tags: tags});
        this.remindUpdated();
    }

    updatePages(pages) {
        this.setState({pages: pages});
    }

    async retrieveEPaperDetails() {
        var result = await getEPaperDetails(this.ePaperID);
        if (result.status === APIResult.SUCCESS) {
            var hashtags = [];
            result
                .data
                .hashtags
                .forEach((eachHashtag) => {
                    hashtags.push(eachHashtag.hashtag_name);
                });
            this.setState({
                tags: hashtags,
                title: result.data.title,
                hashtags: hashtags.join(),
                editMode: 1,
                bracketsInfo: result.data.coor,
                date: getDate(result.data.publish_timestamp),
                time: getTime(result.data.publish_timestamp),
                status: result.data.status,
                pages: result.data.epages,
                epaper_path: result.data.epaper_path
            });
        }
    }

    async addNewEPaper(clone = false) {
        this.updateLoadingScreen(0);
        if(clone)
        {
            this.setState({
                showLoadingScreen: true,
                uploadPercentage: 0,
                loadingScreenMessage: "正在复制电子报中..."});
        }
        const epages = [];

        for (let i = 0; i < this.state.pages.length; i++) {
            this.updateLoadingScreen(i / this.state.pages.length);
            const page = this.state.pages[i]
            while (page.id === null) {
                await new Promise(r => setTimeout(r, 500));
            }
            const pageObject = {
                id: page.id,
                page_index: i
            };
            epages.push(pageObject);
        }

        var newEPages = [];
        if (clone) {
            let clonePages = await ePaperDuplicatePage(this.ePaperID);
            if (clonePages.status === APIResult.SUCCESS) {
                clonePages
                    .result
                    .forEach(eachNewIDSet => {
                        epages.forEach(eachCurrentEPages => {
                            if (eachNewIDSet["old_id"] === eachCurrentEPages["id"]) {
                                newEPages.push({id: eachNewIDSet["new_id"], page_index: eachCurrentEPages["page_index"]});
                            }
                        });
                    });
            }
        }

        var body = {
            title: clone
                ? "「复制」" + this.state.title
                : this.state.title,
            hashtags: this
                .state
                .tags
                .join(","),
            publish_time: Date.parse(this.state.date + " " + this.state.time) / 1000,
            status: clone
                ? 0
                : this.state.status,
            coordinates: this.state.bracketsInfo,
            epages: newEPages.length > 0
                ? newEPages
                : epages
        };

        addNewEPaper(body, this.updateLoadingScreen, this.addNewPaperCallback);
    }

    async updateEPaper() {
        this.setState({uploadPercentage: 0, loadingScreenMessage: "正在编辑电子报中..."});

        const epages = [];

        this
            .state
            .pages
            .forEach((page, index) => {
                const pageObject = {
                    id: page.id,
                    page_index: index
                };
                epages.push(pageObject);
            });

        var updateResult = await updateEPaper(this.ePaperID, this.state.title, this.state.tags.join(","), Date.parse(this.state.date + " " + this.state.time) / 1000, this.state.status, this.state.bracketsInfo, epages,);
        if (updateResult.status === APIResult.SUCCESS) {
            this.setState({showLoadingScreen: false});
            this.enableMessageDialog("上传电子报", "电子报已被保存，点击「好的」即可返回主页。", () => {
                this.enterDashboard(false);
            });
        } else {
            this.setState({showLoadingScreen: false});
            this.enableMessageDialog("上传电子报", "由于发生了一些不明状况，导致目前无法正常编辑电子报，请稍后再试一次。", () => {});
        }
    }

    async deleteEPaper() {
        const epages = [];

        this
            .state
            .pages
            .forEach((page, index) => {
                const pageObject = {
                    id: page.id,
                    page_index: index
                };
                epages.push(pageObject);
            });

        await updateEPaper(this.ePaperID, this.state.title, this.state.tags.join(","), Date.parse(this.state.date + " " + this.state.time) / 1000, 2, this.state.bracketsInfo, epages);

        this.enterDashboard(false);
    }

    enableMessageDialog(title, message, callback, showCancel = false) {
        this.oneTimeCallback = () => {
            callback();
            this.oneTimeCallback = () => {};
            this.setState({showMessageDialog: false, showMessageDialogCancelButton: showCancel});
        };
        this.setState({messageDialogTitle: title, messageDialogMessage: message, showMessageDialog: true});
    }

    updateLoadingScreen(progress) {
        this.setState({
            uploadPercentage: progress,
            loadingScreenMessage: progress >= 0.98
                ? "正在制作电子报中..."
                : "电子报正被上传中..."
        });
    }

    addNewPaperCallback(result) {
        this.setState({showLoadingScreen: false});
        if (result && result.status === APIResult.SUCCESS) {
            this.ePaperID = result.epaper_id;
            this.setState({ePaperID: this.ePaperID})
            this.enableMessageDialog("上传电子报", "电子报已被上传并保存，点击「好的」即可返回主页。", () => {
                this.removeExitConfirmation();
                this.enterDashboard(false)
            });
        } else {
            this.enableMessageDialog("上传电子报", "由于发生了一些不明状况，导致目前无法正常上传电子报，请稍后再试一次。", () => {});
        }
    }

    submitEPaper() {
        this.setState({showLoadingScreen: true});

        if (this.ePaperID === 0) {
            this.addNewEPaper();
        } else {
            this.updateEPaper();
        }
    }

    removeTag = (i) => {
        var newTags = [];
        this
            .state
            .tags
            .forEach((eachTag) => {
                if (eachTag !== i) {
                    newTags.push(eachTag);
                }
            });
        this.setState({tags: newTags});
        this.setState({
            hashtags: this
                .state
                .tags
                .join()
        });
    };

    updateTitle(event) {
        this.setState({title: event.target.value});
        this.remindUpdated();
    }

    addTag(event) {
        if (this.state.tags.length < 20) {
            var currentTagText = event.target.value;
            if (currentTagText.includes(",") || currentTagText.includes("，")) {
                currentTagText = currentTagText
                    .replace(" ", "-")
                    .replace(",", "")
                    .replace("，", "");

                if (!this.state.tags.includes(currentTagText)) {
                    var currentTags = this.state.tags;
                    currentTags.push(currentTagText);
                    this.setState({tags: currentTags, tagText: ""});
                } else {
                    this.setState({tagText: ""});
                }
            } else {
                this.setState({tagText: currentTagText});
            }
            this.setState({
                hashtags: this
                    .state
                    .tags
                    .join()
            });
        }

        this.remindUpdated();
    }

    getItemStyle(isDragging, draggableStyle) {
        return {
            filter: isDragging
                ? "brightness(30%)"
                : "",
            ...draggableStyle
        };
    }

    updateDate(date) {
        this.setState({date: date});
    }

    updateTime(time) {
        this.setState({time: time});
    }

    onGetPDF(pdfFile) {
        this.setState({pdfFile: pdfFile, editMode: 1, percentage: 0, showLoadingScreen: true, loadingScreenMessage: "正在读取PDF中...请稍候..."});
    }

    pdfFullyLoadedFunc() {
        this.setState({showLoadingScreen: false});
    }

    enterDashboard(confirmation = true) {
        if (!confirmation) {
            this.removeExitConfirmation();
        }
        window.location.href = `/dashboard`;
    }

    autoSave() {
        if (this.state.title !== "" || this.state.tags.length > 0 || this.state.bracketsInfo.length > 0) {
            this.setState({autoSaving: true});
            this.submitEPaper();
        }
    }

    render() {
        return (
            <div
                style={{
                position: "relative",
                marginBottom: 64
            }}>
                <div className="full-width center-v f-v">
                    <div
                        className="add-paper-parent"
                        style={{
                        paddingBottom: "1em"
                    }}>
                        <div className="dashboard-top-bar">
                            <div className="dashboard-label">
                                <img
                                    alt="pointer"
                                    style={{
                                    cursor: "pointer",
                                    padding: "0.5em"
                                }}
                                    onClick={this.enterDashboard}
                                    src={BackIcon}/>{" "} {this
                                    .state
                                    .title
                                    .replace(" ", "") === ""
                                    ? "未命名" + (this.state.edited
                                        ? "*"
                                        : "")
                                    : this.state.title + (this.state.edited
                                        ? "*"
                                        : "")}
                            </div>
                            {this.state.autoSaving
                                ? (
                                    <div
                                        className="autosave-title"
                                        style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 19
                                    }}>
                                        <div>自动储存中...</div>
                                        <HashLoader color="black" size={15}/>
                                    </div>
                                )
                                : (
                                    <div
                                        style={{
                                        display: "flex"
                                    }}>
                                        {this.state.ePaperID > 0
                                            ? <img
                                                    alt="pointer"
                                                    className="topbar-button"
                                                    style={{
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => {
                                                        window.open(`https://epaper.atvnewsonline.com/epaper?id=${this.ePaperID}`, "_blank");
                                                }}
                                                    src={PreviewIcon}/>
                                            : null}
                                        {this.state.ePaperID > 0
                                            ? <img
                                                    alt="pointer"
                                                    className="topbar-button"
                                                    style={{
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => {
                                                    this.enableMessageDialog("复制电子报", `请问你是否要复制一份新的[${this.state.title}]电子报？`, () => {
                                                        this.addNewEPaper(true)
                                                    }, true);
                                                }}
                                                    src={DuplicateIcon}/>
                                            : null}
                                        <img
                                            alt="pointer"
                                            className="topbar-button"
                                            style={{
                                            cursor: "pointer",
                                            display: this.state.ePaperID > 0
                                                ? "block"
                                                : "none"
                                        }}
                                            onClick={() => {
                                            this.enableMessageDialog("删除电子报", `请问你是否要删除[${this.state.title}]电子报？`, () => {
                                                this.deleteEPaper()
                                            }, true);
                                        }}
                                            src={DeleteIcon}/>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="add-page-filter">
                        <div
                            style={{
                            paddingBottom: "1em"
                        }}>
                            <div className="title-input">标题</div>
                            <div className="filter-parent">
                                <input
                                    className="filter-input"
                                    onChange={this.updateTitle}
                                    type="text"
                                    value={this.state.title}/>
                            </div>
                        </div>
                        <div
                            style={{
                            paddingBottom: "1em"
                        }}>
                            <div className="title-input">关键字 (最多20个, 金色为最重要关键字)</div>
                            <div
                                className="filter-parent"
                                style={{
                                gap: 4
                            }}>
                                <DragDropContext onDragEnd={this.onDragTagsEnd}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                display: "flex",
                                                overflowX: "auto",
                                                gap: "14px",
                                                paddingBottom: "16px"
                                            }}>
                                                {this
                                                    .state
                                                    .tags
                                                    .map((eachTag, index) => (
                                                        <Draggable key={index} draggableId={"page-" + index} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className="arranger-page"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                                    <InputTag
                                                                        highlight={index < 2}
                                                                        key={"tag_" + eachTag}
                                                                        label={eachTag}
                                                                        onRemove={() => this.removeTag(eachTag)}/>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}

                                                {provided.placeholder}
                                                <input
                                                    className="filter-input"
                                                    onChange={this.addTag}
                                                    type="text"
                                                    value={this.state.tagText}/>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                        <div className="add-page-datetime">
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "1em"
                            }}>
                                <div className="title-input">上传日期</div>
                                <div className="filter-parent">
                                    <input
                                        className="filter-input"
                                        type="date"
                                        value={this.state.date}
                                        onChange={(e) => {
                                        this.setState({date: e.target.value});
                                        this.remindUpdated();
                                    }}/>
                                </div>
                            </div>
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "1em"
                            }}>
                                <div className="title-input">上传时间</div>

                                <div className="filter-parent">
                                    <input
                                        className="filter-input"
                                        type="time"
                                        value={this.state.time}
                                        onChange={(e) => {
                                        this.setState({time: e.target.value});
                                        this.remindUpdated();
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                            paddingBottom: "1em"
                        }}>
                            <div className="title-input">上架模式</div>
                            <div className="filter-parent">
                                <select
                                    className="filter-input"
                                    type="text"
                                    value={this.state.status}
                                    onChange={(e) => {
                                    this.setState({
                                        status: Number(e.target.value)
                                    });
                                    this.remindUpdated();
                                }}>
                                    <option value="0">草稿</option>
                                    <option value="1">开放阅读</option>
                                </select>
                            </div>
                        </div>
                        <div
                            style={{
                            paddingBottom: "1em"
                        }}>
                            <div className="title-input">内容</div>
                            {this.state.editMode === 0
                                ? (<DragAndDropPDFSelector onGetPDF={this.onGetPDF}/>)
                                : null}
                            {this.state.editMode === 1
                                ? (<PDFEditor
                                    bracketsInfo={this.state.bracketsInfo}
                                    pages={this.state.pages}
                                    epaper_path={this.state.epaper_path}
                                    pdf={this.state.pdfFile}
                                    pdfFullyLoadedFunc={this.pdfFullyLoadedFunc}
                                    pagesUpdated={this.updatePages}
                                    exitFunc={() => {
                                    this.setState({editMode: 0, bracketsInfo: [], images: []});
                                }}
                                    updateAllBrackets={(e, remindUpdate) => {
                                    this.setState({bracketsInfo: e});
                                    if (remindUpdate) {
                                        this.remindUpdated();
                                    }
                                }}/>)
                                : null}
                        </div>
                    </div>
                    <div className="add-page-filter">
                        <GenericButton
                            fullWidth={true}
                            fontSize={16}
                            label="存档"
                            onClick={() => this.submitEPaper()}/>
                        <div
                            style={{
                            marginBottom: "2em"
                        }}/>
                    </div>
                </div>
                {this.state.showLoadingScreen
                    ? (<LoadingScreen
                        message={this.state.loadingScreenMessage}
                        percentage={this.state.uploadPercentage}/>)
                    : null}
                {this.state.showMessageDialog
                    ? (<MessageDialog
                        title={this.state.messageDialogTitle}
                        message={this.state.messageDialogMessage}
                        showCancel={this.state.showMessageDialogCancelButton}
                        cancelCallback={() => {
                        this.setState({showMessageDialog: false});
                    }}
                        callback={() => {
                        this.setState({showMessageDialog: false});
                        this.oneTimeCallback();
                    }}/>)
                    : null}
            </div>
        );
    }
}

export default AddEPaperPage;
