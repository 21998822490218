import React from "react";
import PropTypes from "prop-types";
import HighlightBracket from "./HighlightBracket";
import "./PageEditor.scss";
import {
    debugPrint,
} from "../../utilities/Utilities";
import MessageDialog from "../dialogs/MessageDialog";

class PageEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBracketTopLeft: [0, 0],
            currentBracketBottomRight: [0, 0],
            bracketList: {},
            highlighting: false,
        };
        this.trackMouse = false;
        this.currentPos = [0, 0];
        this.allBrackets = {};
        this.highlightArea = React.createRef();
        this.currentIndex = 0;

        this.checkTrack = this.checkTrack.bind(this);
        this.startTrackMouse = this.startTrackMouse.bind(this);
        this.forceUpdateBrackets = this.forceUpdateBrackets.bind(this);
    }

    componentDidMount() {
        var bracketList = {};
        this.props.brackets.forEach((eachBracket) => {
            if (eachBracket.page === this.props.index) {
                this.allBrackets[`bracket_${this.currentIndex}`] = {
                    topLeft: [
                        Number(eachBracket.left),
                        Number(eachBracket.top),
                    ],
                    bottomRight: [
                        Number(eachBracket.left) + Number(eachBracket.width),
                        Number(eachBracket.top) + Number(eachBracket.height),
                    ],
                    url: eachBracket.area_url,
                    type: Number(eachBracket.area_type),
                    page: this.props.index,
                };

                bracketList[`bracket_${this.currentIndex}`] = {
                    topLeft: [
                        Number(eachBracket.left),
                        Number(eachBracket.top),
                    ],
                    bottomRight: [
                        Number(eachBracket.left) + Number(eachBracket.width),
                        Number(eachBracket.top) + Number(eachBracket.height),
                    ],
                    url: eachBracket.area_url,
                    type: Number(eachBracket.area_type),
                    page: this.props.index,
                };
                this.currentIndex += 1;
            }
        });
        this.setState({ bracketList: bracketList });
        this.props.updateBrackets(this.allBrackets, false);
    }

    forceUpdateBrackets(newBrackets)
    {
        var bracketList = {};
        this.allBrackets = {};
        newBrackets.forEach((eachBracket) => {
            if (eachBracket.page === this.props.index) {
                this.allBrackets[`bracket_${this.currentIndex}`] = {
                    topLeft: [
                        Number(eachBracket.left),
                        Number(eachBracket.top),
                    ],
                    bottomRight: [
                        Number(eachBracket.left) + Number(eachBracket.width),
                        Number(eachBracket.top) + Number(eachBracket.height),
                    ],
                    url: eachBracket.area_url,
                    type: Number(eachBracket.area_type),
                    page: this.props.index,
                };

                bracketList[`bracket_${this.currentIndex}`] = {
                    topLeft: [
                        Number(eachBracket.left),
                        Number(eachBracket.top),
                    ],
                    bottomRight: [
                        Number(eachBracket.left) + Number(eachBracket.width),
                        Number(eachBracket.top) + Number(eachBracket.height),
                    ],
                    url: eachBracket.area_url,
                    type: Number(eachBracket.area_type),
                    page: this.props.index,
                };
                this.currentIndex += 1;
            }
        });
        this.setState({ bracketList: bracketList });
    }

    checkTrack() {
        if (this.trackMouse) {
            this.currentIndex += 1;
            this.highlightArea.current.removeEventListener(
                "mousemove",
                this.startTrackMouse,
                false
            );
            var currentBracketList = this.state.bracketList;
            currentBracketList[`bracket_${this.currentIndex}`] = {
                topLeft: [
                    Math.min(
                        this.state.currentBracketTopLeft[0],
                        this.currentPos[0]
                    ),
                    Math.min(
                        this.state.currentBracketTopLeft[1],
                        this.currentPos[1]
                    ),
                ],
                bottomRight: [
                    Math.max(
                        this.state.currentBracketTopLeft[0],
                        this.currentPos[0]
                    ),
                    Math.max(
                        this.state.currentBracketTopLeft[1],
                        this.currentPos[1]
                    ),
                ],
                url: "",
                type: 0,
            };

            this.setState(
                {
                    bracketList: currentBracketList,
                    currentBracketTopLeft: [0, 0],
                },
                () => {
                    Object.keys(this.state.bracketList).forEach(
                        (eachBracketName) => {
                            if (
                                !Object.keys(this.allBrackets).includes(
                                    eachBracketName
                                )
                            )
                                this.allBrackets[eachBracketName] = {
                                    topLeft:
                                        this.state.bracketList[eachBracketName]
                                            .topLeft,
                                    bottomRight:
                                        this.state.bracketList[eachBracketName]
                                            .bottomRight,
                                    url: "https://",
                                    type: this.state.bracketList[
                                        eachBracketName
                                    ].type,
                                    page: this.props.index,
                                };
                        }
                    );
                    this.props.updateBrackets(this.allBrackets, true);
                }
            );
        } else {
            this.setState(
                {
                    currentBracketTopLeft: [0, 0],
                    currentBracketBottomRight: [0, 0],
                },
                () => {
                    this.highlightArea.current.addEventListener(
                        "mousemove",
                        this.startTrackMouse,
                        false
                    );
                }
            );
        }
        this.trackMouse = !this.trackMouse;
        this.setState({ trackMouse: this.trackMouse });
    }

    startTrackMouse(e) {
        this.currentPos = [
            Math.round(
                ((e.offsetX /
                    this.highlightArea.current.getBoundingClientRect().width) *
                    100 +
                    Number.EPSILON) *
                    100
            ) / 100,
            Math.round(
                ((e.offsetY /
                    this.highlightArea.current.getBoundingClientRect().height) *
                    100 +
                    Number.EPSILON) *
                    100
            ) / 100,
        ];

        if (
            this.state.currentBracketTopLeft[0] === 0 &&
            this.state.currentBracketTopLeft[1] === 0
        ) {
            this.setState({ currentBracketTopLeft: this.currentPos });
        } else {
            this.setState({ currentBracketBottomRight: this.currentPos });
        }
    }

    enableMessageDialog(title, message, callback, showCancel = false) {
        this.oneTimeCallback = () => {
            callback();
            this.oneTimeCallback = () => {};
            this.setState({
                showMessageDialog: false,
                showMessageDialogCancelButton: showCancel,
            });
        };
        this.setState({
            messageDialogTitle: title,
            messageDialogMessage: message,
            showMessageDialog: true,
        });
    }

    render() {
        return (
            <div className="page-editor-pages-parent">
                <div className="page-editor-pages-title">
                    第{this.props.index + 1}/{this.props.total}页
                </div>
                <div className="page-editor-page-highlight-area">
                    <img
                        alt="content"
                        className="page-editor-page"
                        src={this.props.image}
                        ref={this.highlightArea}
                        onClick={this.checkTrack}
                    ></img>
                    {this.state.currentBracketTopLeft[0] !== 0 &&
                    this.state.currentBracketBottomRight[0] !== 0 ? (
                        <HighlightBracket
                            generating={true}
                            topLeft={this.state.currentBracketTopLeft}
                            bottomRight={this.state.currentBracketBottomRight}
                        />
                    ) : null}
                    {Object.keys(this.state.bracketList).map(
                        (eachBracketName) => {
                            let eachBracket =
                                this.state.bracketList[eachBracketName];
                            return (
                                <HighlightBracket
                                    url={eachBracket.url}
                                    type={eachBracket.type}
                                    key={`${this.props.index}_${eachBracketName}`}
                                    topLeft={eachBracket.topLeft}
                                    bottomRight={eachBracket.bottomRight}
                                    onUpdate={(url, type) => {
                                        this.allBrackets[eachBracketName][
                                            "url"
                                        ] = url;
                                        this.allBrackets[eachBracketName][
                                            "type"
                                        ] = type;
                                        this.props.updateBrackets(
                                            this.allBrackets,
                                            true
                                        );
                                    }}
                                    onDelete={() => {
                                        var currentBracketList =
                                            this.state.bracketList;
                                        delete currentBracketList[
                                            eachBracketName
                                        ];
                                        // var currentBracket = {};
                                        delete this.allBrackets[
                                            eachBracketName
                                        ];
                                        // this.allBrackets = currentBracket;
                                        this.setState({
                                            bracketList: currentBracketList,
                                        });
                                        this.props.updateBrackets(
                                            this.allBrackets,
                                            true
                                        );
                                    }}
                                />
                            );
                        }
                    )}
                </div>
                {this.state.showMessageDialog ? (
                    <MessageDialog
                        title={this.state.messageDialogTitle}
                        message={this.state.messageDialogMessage}
                        showCancel={this.state.showMessageDialogCancelButton}
                        cancelCallback={() => {
                            this.setState({ showMessageDialog: false });
                        }}
                        callback={() => {
                            this.setState({ showMessageDialog: false });
                            this.oneTimeCallback();
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

PageEditor.propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    image: PropTypes.string,
    updateBrackets: PropTypes.func,
};

PageEditor.defaultProps = {
    index: 0,
    total: 0,
    image: "",
    updateBrackets: () => {
        debugPrint("On Page Editor Update...");
    },
};

export default PageEditor;
