export function debugPrint(...args) {
  if (isDebug) {
    console.log(...args);
  }
}

export function isDebug()
{
  return (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
}

export function convertDateTime(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function getDate(epoch) {
  var date = new Date(epoch * 1000);
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
}

export function n(n) {
  return n > 9 ? "" + n : "0" + n;
}

export function getTime(epoch) {
  var date = new Date(epoch * 1000);
  return `${n(date.getHours())}:${n(date.getMinutes())}`;
}

const PDFJS = require("pdfjs-dist/webpack");

export function readFileData(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
}

export async function convertPDFToImages(file) {
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);

    var viewport = page.getViewport({
      scale: 1,
    });

    if(viewport.width < 2500){
      const newScale = Math.ceil(2500/viewport.width);
      viewport = page.getViewport({
        scale: newScale,
      });
      debugPrint({newScale});
    }
    
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    debugPrint({height: viewport.height, width: viewport.height});

    await page.render({
      canvasContext: context,
      viewport: viewport,
    }).promise;
    images.push(canvas.toDataURL("image/jpeg"));
    // canvas.toBlob(function(blob) {
    //   images.push(new File([blob], "image.jpeg"));
    // },'image/jpeg', 1);
  }
  canvas.remove();
  return images;
}

export function convertToTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function getTodayDateString(offset = 0) {
  var today = new Date();

  if (offset !== 0) {
    today.setDate(today.getDate() + offset);
  }

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function checkSessionExpired() {
  if (
    localStorage.getItem("id") === null &&
    localStorage.getItem("jwt") === null
  ) {
    window.location.href = `/login`;
  }
}

export function convertToEpoch(dateTime) {
  var date = dateTime.concat("-00-00-00");
  var d = date.split("-");
  var epoch = new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]).valueOf();
  return epoch / 1000;
}

export function isValidMonth(month) {
  return month !== "total" && month.startsWith("m-");
}

export function jsonDataValid(data) {
  if (data === undefined || data === null) {
    return false;
  }
  var jsonData = JSON.parse(data);
  return !(jsonData === undefined || jsonData === null);
}
export function calcMaxNum(array) {
  return Math.max.apply(null, array);
}
export function convert(value) {
  if (value >= 1000000) {
    value = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(2) + "K";
  }
  return value;
}
