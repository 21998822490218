import { getTodayDateString } from "../utilities/Utilities";

export const DEFAULT_EPAPER_INFO = {
  epaper_id: 0,
  title: "",
  page_count: 0,
  read_count: 0,
  epaper_path: "",
  publish_date_string: "",
  publish_time_string: "",
  publish_timestamp: 0,
  upload_date_string: getTodayDateString(),
  upload_time_string: "12:00",
  upload_timestamp: 0,
  hashtags: [],
  coor: [],
};

export const DEFAULT_MONTHLY_READING_TRENDS_COUNT = {
  "m-0": 0,
  "m-1": 0,
  "m-2": 0,
  "m-3": 0,
  "m-4": 0,
  "m-5": 0,
  total: 0,
};

export const DEFAULT_DAILY_READING_TRENDS_COUNT = {
  "m-0": 0,
  "m-1": 0,
  "m-2": 0,
  "m-3": 0,
  "m-4": 0,
  "m-5": 0,
  "m-6": 0,
  total: 0,
};
