import React from 'react';
import PropTypes from 'prop-types';
import "./GenericButton.scss";

class GenericButton extends React.Component {
    render() {
        return (
            <div
                className='generic-button'
                onClick={this.props.onClick}>
                {this.props.label}
            </div>
        );
    }
}

GenericButton.propTypes = {
    label: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.number,
    secondary: PropTypes.bool,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    enable: PropTypes.bool,
    onClick: PropTypes.func,
    borderRadius: PropTypes.string,
    padding: PropTypes.number,
    backgroundColor: PropTypes.string,
    width: PropTypes.string
}

GenericButton.defaultProps = {
    label: 'GenericButton',
    size: 'large',
    textColor: "white",
    color: "red",
    backgroundColor: "#EE4139",
    fontSize: "14px",
    secondary: false,
    fullWidth: false,
    fullHeight: false,
    enable: true,
    borderRadius: "10px",
    padding: 16


};

export default GenericButton;
