import React from 'react';
import PropTypes from 'prop-types';
import "./E-PaperCard.scss";
import Tag from './Tag';
import { debugPrint } from '../../utilities/Utilities';

class EPaperCard extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            tags: {}
        };
    }
    componentDidMount() {}

    render()
    {
        return (
            <div className='e-paper-card-parent'>
                <img className='e-paper-thumbnail' src={this.props.thumbnail} alt='e-paper'/>
                <div
                    className='p-a-s e-paper-right'
                    style={{
                    flex: 1
                }}>
                    <div className='e-paper-top-row'>
                        <div className='e-paper-title'>{this.props.title}</div>
                        <div className='e-paper-date'>{this.props.date}</div>
                    </div>
                    <div className='e-paper-bottom-row'>
                        <div className='e-paper-tags-parent'>
                            {this
                                .props
                                .tags
                                .map((tags, index) => {
                                    return (<Tag key={index} label={tags.hashtag_name}/>);
                                })}
                        </div>
                        <div onClick={this.props.enterEdit} className='edit-button'>
                            <p>编辑</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EPaperCard.propTypes = {
    path: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    enterEdit: PropTypes.func,
    tags: PropTypes.array,
    thumbnail: PropTypes.string

};

EPaperCard.defaultProps = {
    thumbnail: "",
    title: '',
    date: "",
    enterEdit: function () {
        debugPrint("Entering AddEPaper");
    },
    tags: []
};

export default EPaperCard;
