import React from "react";
import CardFrame from "../widgets/CardFrame";
import EPaperTopReadCard from "../widgets/E-PaperTopReadCard";
import ReadingTrends from "../widgets/ReadingTrends";
import PageNavigation from "../widgets/PageNavigation";
import {getEPaperAnalysis, getEPaperPerformance} from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import Number1Icon from "../../images/no1_icon.svg";
import Number2Icon from "../../images/no2_icon.svg";
import Number3Icon from "../../images/no3_icon.svg";
import Number4Icon from "../../images/no4_icon.svg";
import {DEFAULT_DAILY_READING_TRENDS_COUNT, DEFAULT_MONTHLY_READING_TRENDS_COUNT} from "../../configs/Default";
import {calcMaxNum, isValidMonth, jsonDataValid, convert} from "../../utilities/Utilities";
import LoadingScreen from "../widgets/LoadingScreen";

class AnalyticsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            EPaperPeformances: [],
            topEPaperCards: [],
            dailyTopReadings: {},
            monthlyTopReadings: {},
            loading: true,
            currentPageDaily: 0,
            totalPageDaily: 0
        };

        this.itemPerPage = 10;

        this.getEPaperAnalysis = this
            .getEPaperAnalysis
            .bind(this);
        this.getEPaperPerformance = this
            .getEPaperPerformance
            .bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem("refresh") === "true") {
            this.listenToRefresh = setInterval(this.getEPaperPerformance, parseInt(localStorage.getItem("refreshFrequency")) * 60000);
        } else {
            this.getEPaperPerformance();
        }

        this.getEPaperAnalysis();
    }

    getRankingIcon(rank) {
        if (rank === 1) {
            return Number1Icon;
        } else if (rank === 2) {
            return Number2Icon;
        } else if (rank === 3) {
            return Number3Icon;
        } else if (rank === 4) {
            return Number4Icon;
        }
    }

    async getEPaperPerformance() {
        var performanceResult = await getEPaperPerformance(localStorage.getItem("userID"), localStorage.getItem("jwt"), this.state.currentPageDaily, this.itemPerPage);
        if (performanceResult.status === APIResult.SUCCESS) {
            this.setState({EPaperPeformances: performanceResult.data["performance"], totalPageDaily: performanceResult.data["total"]});
        }
    }

    async getEPaperAnalysis(firstCall = true) {
        var currentCallTime = Date.parse(new Date()) / 1000;
        var pastCallTime = Date.parse(new Date()) / 1000;

        var proceed = !firstCall; //true
        if (firstCall) {
            //false
            if (currentCallTime - parseInt(localStorage.getItem("GetAnalysisCallTime")) > 5) {
                proceed = true;
            }
        }

        if (proceed || !jsonDataValid(localStorage.getItem("topEPaperCards")) || !jsonDataValid(localStorage.getItem("dailyTopReadings")) || !jsonDataValid(localStorage.getItem("monthlyTopReadings"))) 
        {
            var analysisResult = await getEPaperAnalysis(localStorage.getItem("userID"), localStorage.getItem("jwt"));
            if (analysisResult.status === APIResult.SUCCESS) {
                this.setState({
                    topEPaperCards: analysisResult.data["top-e-papers"],
                    dailyTopReadings: analysisResult.data["top-reading-trends"].daily_top_readings,
                    monthlyTopReadings: analysisResult.data["top-reading-trends"].monthly_top_readings,
                    dailyTopReadingsHighest: calcMaxNum(this.getAllMonths(analysisResult.data["top-reading-trends"].daily_top_readings, DEFAULT_DAILY_READING_TRENDS_COUNT)),
                    monthlyTopReadingsHighest: calcMaxNum(this.getAllMonths(analysisResult.data["top-reading-trends"].monthly_top_readings, DEFAULT_MONTHLY_READING_TRENDS_COUNT))
                });

                localStorage.setItem("topEPaperCards", JSON.stringify(analysisResult.data["top-e-papers"]));

                localStorage.setItem("dailyTopReadings", JSON.stringify(analysisResult.data["top-reading-trends"].daily_top_readings));

                localStorage.setItem("monthlyTopReadings", JSON.stringify(analysisResult.data["top-reading-trends"].monthly_top_readings));
                localStorage.setItem("monthlyTopReadingsHighest", calcMaxNum(this.getAllMonths(analysisResult.data["top-reading-trends"].monthly_top_readings, DEFAULT_MONTHLY_READING_TRENDS_COUNT)));

                localStorage.setItem("dailyTopReadingsHighest", calcMaxNum(this.getAllMonths(analysisResult.data["top-reading-trends"].daily_top_readings, DEFAULT_DAILY_READING_TRENDS_COUNT)));
                localStorage.setItem("GetAnalysisCallTime", pastCallTime);
            }
        } else {

            this.setState({
                topEPaperCards: JSON.parse(localStorage.getItem("topEPaperCards")),
                dailyTopReadings: JSON.parse(localStorage.getItem("dailyTopReadings")),
                monthlyTopReadings: JSON.parse(localStorage.getItem("monthlyTopReadings")),
                dailyTopReadingsHighest: localStorage.getItem("dailyTopReadingsHighest"),
                monthlyTopReadingsHighest: localStorage.getItem("monthlyTopReadingsHighest")
            });

            localStorage.setItem("GetAnalysisCallTime", pastCallTime);
        }
        this.setState({loading: false});
    }

    getAllMonths(data, array) {
        var allMonths = [];
        Object
            .keys(array)
            .forEach((eachKey) => {
                if (isValidMonth(eachKey)) {
                    let currentVal = data[eachKey];
                    if (currentVal !== undefined && currentVal != null && !isNaN(currentVal)) {
                        allMonths.push(currentVal);
                    } else {
                        allMonths.push(0);
                    }
                }
            });
        return allMonths;
    }

    render() {
        return (
            <div>
                {this.state.loading
                    ? <LoadingScreen/>
                    : null}

                <div
                    className="dashboard-content"
                    style={{
                    paddingTop: "5em"
                }}>
                    <div className="dashboard-filter">
                        <CardFrame title="阅读量趋势">
                            <ReadingTrends
                                highest={this.state.monthlyTopReadingsHighest}
                                data={this.state.monthlyTopReadings}/>
                        </CardFrame>

                        <CardFrame title="阅读量趋势">
                            <ReadingTrends
                                dailyMode={true}
                                showDays={true}
                                highest={this.state.dailyTopReadingsHighest}
                                data2={this.state.dailyTopReadings}/>
                        </CardFrame>
                    </div>
                    <div
                        className="ePaperPage-filter"
                        style={{
                        paddingTop: "1em"
                    }}>
                        <CardFrame title="热门排行榜">
                            <div
                                style={{
                                padding: "0em"
                            }}
                                className="dashboard-filter">
                                {this
                                    .state
                                    .topEPaperCards
                                    .slice(0, 4)
                                    .map((topEPaper, index) => {
                                        return (<EPaperTopReadCard
                                            key={`top-read-card-${index}`}
                                            thumbnail={topEPaper
                                            .epaper_path
                                            .concat(topEPaper.epaper_thumbnail)}
                                            title={topEPaper.title}
                                            date={topEPaper.publish_date_string}
                                            viewCount={convert(topEPaper.read_count)}
                                            rankingIcon={this.getRankingIcon(topEPaper.ranking)}/>);
                                    })}
                            </div>
                        </CardFrame>
                    </div>

                    <div
                        className="ePaperPage-filter"
                        style={{
                        paddingTop: "1em"
                    }}>
                        <div className="daily-readings-parent">
                            <div
                                className="daily-readings-title"
                                style={{
                                paddingBottom: "1em"
                            }}>
                                每日阅读次数
                            </div>
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <table
                                    className="daily-reading-table"
                                    style={{
                                    width: "100%"
                                }}>
                                    <tbody>
                                        <tr className="daily-readings-header">
                                            <td>
                                                <div>日期</div>{" "}
                                            </td>
                                            <td>
                                                <div>标题</div>{" "}
                                            </td>
                                            <td>
                                                <div>
                                                    阅读数</div>
                                            </td>
                                        </tr>

                                        {this
                                            .state
                                            .EPaperPeformances
                                            .map((dailyEPaper, index) => {
                                                return (
                                                    <tr key={`result-${index}`}>
                                                        <td
                                                            style={{
                                                            width: "15px",
                                                            whiteSpace: "nowrap"
                                                        }}>
                                                            {" "}
                                                            {dailyEPaper.publish_date_string}
                                                        </td>
                                                        <td
                                                            style={{
                                                            width: "80%"
                                                        }}>
                                                            {" "}
                                                            {dailyEPaper.title}
                                                        </td>
                                                        <td
                                                            style={{
                                                            width: "20px"
                                                        }}>
                                                            {" "}
                                                            {convert(dailyEPaper.read_count)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <div
                                    style={{
                                    margin: 15
                                }}>
                                    <PageNavigation
                                        currentPage={this.state.currentPageDaily + 1}
                                        total={Math.ceil(this.state.totalPageDaily/this.itemPerPage)}
                                        onPageChange={(e) => {
                                            this.setState({currentPageDaily: e - 1}, ()=>{
                                                this.getEPaperPerformance();
                                            })
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AnalyticsPage;
