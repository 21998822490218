import React from 'react';
import "./Pages.scss";
import UploadIcon from '../../images/upload.svg';
import EPaperListPage from './EPaperListPage';
import AnalyticsPage from './AnalyticsPage';
import NavigationButton from '../widgets/NavigationButton';
import EPaperOffIcon from "../../images/epaper_off.svg";
import EPaperOnIcon from "../../images/epaper_on.svg";
import AnalyticsOnIcon from "../../images/analytics_on.svg";
import AnalyticsOffIcon from "../../images/analytics_off.svg";
import SettingsPage from './SettingsPage';

class DashboardPage extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            title: "",
            previousPage: -1,
            currentPage: 1
        };

        this.allPages = [
            {
                id: 1,
                label: "EPaperList Page",
                icon1: EPaperOnIcon,
                icon2: EPaperOffIcon,
                display: true
            }, {
                id: 2,
                label: "Analytics Page",
                icon1: AnalyticsOnIcon,
                icon2: AnalyticsOffIcon,
                display: true
            },
            // {     id: 3,     label: "Settings Page",     icon1: SettingsOnIcon,
            // icon2: SettingsOffIcon,     display: true }
        ];

        this.getPage = this
            .getPage
            .bind(this);
        this.updatePage = this
            .updatePage
            .bind(this);
        this.updateState = this
            .updateState
            .bind(this);
        this.getTitle = this
            .getTitle
            .bind(this);
        this.enterAddEPaperPage = this
            .enterAddEPaperPage
            .bind(this);
        this.enterDashboardLogin = this
            .enterDashboardLogin
            .bind(this);
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount()
    {
        this._isMounted = false;
    }

    enterAddEPaperPage() {
        window.location.href = `/addepaper`;
    }

    enterDashboardLogin() {
        window.location.href = `/login`;
    }

    getPage()
    {
        if (this.state.currentPage === 1) {
            return <EPaperListPage/>;
        }
        if (this.state.currentPage === 2) {
            return <AnalyticsPage/>;
        }

        if (this.state.currentPage === 3) {
            return <SettingsPage/>
        }
        return null;
    }

    getTitle(page)
    {
        var title = ""
        if (page === 1) {
            title = "电子报"
        } else if (page === 2) {
            title = "分析"
        } else if (page === 3) {
            title = "设定"
        }
        return title
    }

    updatePage(index)
    {
        this.updateState({previousPage: this.state.currentPage, currentPage: index})
    }

    updateState(state)
    {
        if (this._isMounted) {
            this.setState(state);
        }
    }

    render()
    {
        return (
            <div className='full-width center-v f-v'>
                <div className='dashboard-parent'>
                    <div className='dashboard-top-bar'>
                        <div className="dashboard-label">{this.getTitle(this.state.currentPage)}</div>
                        {this.state.currentPage === 1
                            ? <img
                                    className='button-desktop'
                                    alt="point"
                                    style={{
                                    cursor: "pointer"
                                }}
                                    onClick={this.enterAddEPaperPage}
                                    src={UploadIcon}/>
                            : null}
                    </div>
                </div>
                {this.getPage()}
                <div className='navigation-bar-parent'>
                    <div className='navigation-bar'>
                        {this
                            .allPages
                            .map((eachPage, index) => {
                                return (<NavigationButton
                                    key={index}
                                    enable={this.state.currentPage === eachPage.id}
                                    image={this.state.currentPage === eachPage.id
                                    ? eachPage.icon1
                                    : eachPage.icon2}
                                    onClick={() => this.updatePage(eachPage.id)}/>);
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

export default(DashboardPage);