import React from 'react';
import PropTypes from 'prop-types';
import "./HighlightBracket.scss";
import DeleteHighlightButton from "../../images/delete_highlight.svg";
import {debugPrint} from '../../utilities/Utilities';

class HighlightBracket extends React.Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            url: "https://",
            type: 0
        };

        this.updateInput = this
            .updateInput
            .bind(this);
        this.updateType = this
            .updateType
            .bind(this);
    }

    componentDidMount()
    {
        if(this.props.url !== "")
        {
            this.setState({url: this.props.url, type: this.props.type}, () => {
                this.props.onUpdate(this.state.url, this.state.type);
            });
        }   
    }

    updateInput(e)
    {
        var currentType = this.state.type;
        if (e.target.value.endsWith(".mp4") || e.target.value.endsWith(".m4v"))
        {
            currentType = 1;
        }
        if (e.target.value.startsWith("https://")) {
            this.setState({url: e.target.value, type: currentType});
            this
                .props
                .onUpdate(e.target.value, currentType);
        }
        if (e.target.value === "") {
            this.setState({url: "https://", type: currentType});
            this
                .props
                .onUpdate("https://", currentType);
        }
    }

    updateType(type)
    {
        this
            .props
            .onUpdate(this.state.url, type);
        this.setState({type: type});
    }

    render()
    {
        return (
            <div
                className="bracket"
                style={{
                width: `${Math.abs(this.props.bottomRight[0] - this.props.topLeft[0])}%`,
                height: `${Math.abs(this.props.bottomRight[1] - this.props.topLeft[1])}%`,
                left: `${Math.min(this.props.topLeft[0], this.props.bottomRight[0])}%`,
                top: `${Math.min(this.props.topLeft[1], this.props.bottomRight[1])}%`,
                opacity: this.props.clickThrough
                    ? 0.5
                    : 1
            }}>
                <img
                    onClick={()=>{
                        if(!this.props.generating)
                        {
                            this.props.onDelete();
                        }
                    }}
                    className='delete-highlight-button'
                    alt='delete'
                    style={{
                        pointerEvents: this.props.generating ? "none" : "visible"
                    }}
                    src={DeleteHighlightButton}/> {!this.props.generating
                    ? <div className='type-parent'>
                            <div
                                onClick={() => this.updateType(0)}
                                className={this.state.type === 0
                                ? 'type-button-selected'
                                : 'type-button-not-selected'}>
                                链接
                            </div>
                            <div
                                onClick={() => this.updateType(1)}
                                className={this.state.type === 1
                                ? 'type-button-selected'
                                : 'type-button-not-selected'}>
                                视频
                            </div>
                        </div>
                    : null}
                {!this.props.generating
                    ? <textarea
                            wrap='soft'
                            className='bracket-input'
                            onChange={this.updateInput}
                            value={this.state.url}/>
                    : null}
            </div>
        );
    }
}

HighlightBracket.propTypes = {
    topLeft: PropTypes.array,
    bottomRight: PropTypes.array,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    generating: PropTypes.bool,
    url: PropTypes.string,
    type: PropTypes.number
};

HighlightBracket.defaultProps = {
    topLeft: [
        0, 0
    ],
    bottomRight: [
        0, 0
    ],
    onUpdate: () => {
        debugPrint("Updating bracket")
    },
    onDelete: () => {
        debugPrint("Deleting current bracker...")
    },
    generating: false,
    url: "https://",
    type: -1
}

export default HighlightBracket;