import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import APIPaths from "./configs/APIPaths";
import APIHeader from "./configs/APIHeader";

axios.defaults.baseURL = APIPaths.apiEndPoint;
axios.defaults.headers.apikey = APIHeader.apiKey;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function to
// log results (for example: reportWebVitals(console.log)) or send to an
// analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
