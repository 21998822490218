import React from 'react';
import PropTypes from 'prop-types';
import HashLoader from "react-spinners/HashLoader";

class LoadingScreen extends React.Component
{
    render()
    {
        return <div
            style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "#00000080",
            display: "flex",
            flexDirection: "column",
            gap: 32,
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: 24,
            zIndex: 100
        }}>
            <div>{this.props.message}</div>
            <HashLoader color={"#FFFFFF"}/> {this.props.percentage > 0
                ? <div
                        style={{
                        width: "80%",
                        maxWidth: 600,
                        background: "#FFFFFF20",
                        height: 24,
                        borderRadius: 12,
                        padding: 4,
                        boxSizing: "border-box"
                    }}>
                        <div
                            style={{
                            width: (this.props.percentage * 100) + "%",
                            background: "#FFFFFF",
                            height: "100%",
                            borderRadius: 12
                        }}/>
                    </div>
                : null}
            {this.props.percentage > 0
                ? <div>{Math.ceil(this.props.percentage * 100) + "%"}</div>
                : null}
        </div>;
    }
}

LoadingScreen.propTypes = {
    message: PropTypes.string,
    percentage: PropTypes.number
};

LoadingScreen.defaultProps = {
    message: "",
    percentage: 0
}

export default LoadingScreen;