import React from "react";
import PropTypes from "prop-types";
import "./ArrangerPage.scss";
import MessageDialog from "../dialogs/MessageDialog";
import AddPageIcon from "../../images/add-page-icon.svg";
import ReuploadPageIcon from "../../images/reupload-page-icon.svg";
import RemovePageIcon from "../../images/remove-page-icon.svg";

class ArrangerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    enableMessageDialog(title, message, callback, showCancel = false) {
        this.oneTimeCallback = () => {
            callback();
            this.oneTimeCallback = () => {};
            this.setState({
                showMessageDialog: false,
                showMessageDialogCancelButton: showCancel,
            });
        };
        this.setState({
            messageDialogTitle: title,
            messageDialogMessage: message,
            showMessageDialog: true,
        });
    }

    render() {
        return (
            <div className="page-arranger-parent">
                <img
                    alt="content"
                    className="page-arranger-page"
                    src={this.props.image}
                    ref={this.highlightArea}
                    onClick={this.checkTrack}
                ></img>
                <div className="controls">
                    <label className="add-button btn">
                        <input
                            type="file"
                            name="upload"
                            accept="image/jpeg,image/png,application/pdf"
                            onChange={this.props.onUploadFile}
                        />
                        <img src={AddPageIcon} alt="" />
                    </label>
                    <label className="reupload-button btn">
                        <input
                            type="file"
                            name="upload"
                            accept="image/jpeg,image/png,application/pdf"
                            onChange={(e) => this.props.onUploadFile(e, true)}
                        />
                        <img src={ReuploadPageIcon} alt="" />
                    </label>
                    <div
                        className="delete-button btn"
                        onClick={() => {
                            this.enableMessageDialog(
                                "删除电子报页面",
                                `请问你是否要删除页面？`,
                                this.props.deletePage,
                                true
                            );
                        }}
                    >
                        <img src={RemovePageIcon} alt="" />
                    </div>
                </div>
                <div className="page-number">
                    第{this.props.index + 1}/{this.props.total}页
                </div>
                {this.state.showMessageDialog ? (
                    <MessageDialog
                        title={this.state.messageDialogTitle}
                        message={this.state.messageDialogMessage}
                        showCancel={this.state.showMessageDialogCancelButton}
                        cancelCallback={() => {
                            this.setState({ showMessageDialog: false });
                        }}
                        callback={() => {
                            this.setState({ showMessageDialog: false });
                            this.oneTimeCallback();
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

ArrangerPage.propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    image: PropTypes.string,
};

ArrangerPage.defaultProps = {
    index: 0,
    total: 0,
    image: "",
};

export default ArrangerPage;
